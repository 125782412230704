import * as React from "react"
import classnames from 'classnames'
import { 
  ComponentContainer
} from './index.module.less'

export interface CardSA2Params {
  className: string
  icon: any
  iconAlt: string
  title: string
  desc: string
}

const CardSA2 = (params: CardSA2Params) => {
  return (
    <div className={classnames(ComponentContainer, params.className)}>
      <img src={params.icon} alt="" className="icon" />
      <h3 className="title">{params.title}</h3>
      <div className="divider"></div>
      <p className="desc">{params.desc}</p>
    </div>
  );
}

export default CardSA2;