import * as React from "react"
import Seo from "components/seo"
import Layout from "layouts/zh"
import Button from 'components/Base/Button'
import SectionContent from 'components/SectionContent'
import BannerSA23 from '../../../components/BannerSA2_3'
import CardSA2 from 'components/CardSA2'
import MediaListSA from 'components/MediaListSA'
import {
  PageContainer
} from './index.module.less'
import IconBanner from 'assets/images/product/sa_2_3/icon-banner.png'
import IconNew from 'assets/images/product/sa_2_3/icon-new.svg'
import IconOld from 'assets/images/product/sa_2_3/icon-loyal.svg'
import Thumb01 from 'assets/images/product/sa_2_3/thumb-01.png'
import Thumb02 from 'assets/images/product/sa_2_3/thumb-02.png'
import Thumb03 from 'assets/images/product/sa_2_3/thumb-03.png'

const SA2_3 = () => {

  return (
    <Layout headerType="black">
      <Seo 
        title="神策分析全新升级｜发布 2.3 版本"
        description="神策分析 2.3 为您带来智能、可靠的预警分析能力，基于场景商店为您提供场景化的业务看板，以全新导航栏助您构建完整的数据驱动闭环，达成“感知-决策-行动-反馈”的效率飞跃"
        keywords="预警分析能力,场景化数据看板,数据驱动闭环,神策分析2.3"
        saTitle="首页-产品（数据应用产品）-神策分析2.3" />
      <main className={PageContainer}>
        <BannerSA23
          className="oval banner-wrap"
          tips="分析云之"
          title={<>神策分析 2.3 <br className="hidden-mb" />全新版本来袭</>}
          desc="神策分析 2.3 为您带来智能、可靠的预警分析能力，基于场景商店为您提供场景化的业务看板，以全新导航栏助您构建完整的数据驱动闭环，达成“感知-决策-行动-反馈”的效率飞跃。"
          buttonList={[
            <Button 
              btnType="gradients" 
              round
              href="/doMatrixSa"
            >体验全新 Demo<img className="icon-arrow" src="https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/icons/arrow-white.svg" alt="" /></Button>,
            <Button 
              btnType="gradients" 
              round 
              plain
              href="https://www.sensorsdata.cn/service/onlinemore.html?section=41&type=%E7%A5%9E%E7%AD%96%E5%88%86%E6%9E%90%202.3"
            >观看培训视频<img className="icon-arrow" src="https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/icons/arrow-black.svg" alt="" /></Button>
          ]}
          poster={IconBanner}
          videoSource={[
            {
              src: "https://video.sensorsdata.cn/f971807352374183a916885721d3cbe3/44ecf5039ac745459124857c7a80fdc5-d6170fecf7591ccc910ec687523907c3-sd.mp4",
              type: "video/mp4"
            }
          ]}
        ></BannerSA23>
        <SectionContent className="new-power" title="三大全新能力，让感知更智能，决策更可靠，行动更高效！">
          <MediaListSA className=""
            list={[
              {
                className: 'np-01',
                title: '智能预警分析',
                desc: '为您的业务提供可靠的风险管理',
                list: [
                  '以先进的 Prophet 算法，智能预测业务指标在一定时间内的变化区间，提升科学性与便捷性，同时避免人工计算误差与经验主义的影响',
                  '支持 Webhook 能力，可通过平台内、办公通讯工具、邮件等多渠道推送告警通知',
                  '基于自动化的指标相关性分析，定位异常数据维度与用户，迅速发现指标关联与潜在风险，助您提升问题排查的效率'
                ],
                buttonList: [
                  <Button 
                    btnType="gradients" 
                    round
                    href="/doMatrixSa"
                  >体验全新 Demo<img className="icon-arrow" src="https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/icons/arrow-white.svg" alt="" style={{marginLeft: 10}}/></Button>,
                  <Button 
                    btnType="gradients" 
                    round
                    plain
                    href="https://manual.sensorsdata.cn/sa/latest/guide_warning-33915690.html"
                  >阅读产品文档<img className="icon-arrow" src="https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/icons/arrow-black.svg" alt="" style={{marginLeft: 10}}/></Button>
                ],
                img: Thumb01,
                imgAlt: 'thumb',
                imgWidth: 617
              },
              {
                className: 'np-02',
                title: '场景商店',
                desc: '内嵌多行业场景库，一键配置行业高质量概览',
                list: [
                  '场景商店内的概览模板来自 1500+ 客户与 30+ 行业的服务经验沉淀，包括通用场景与行业化场景',
                  '场景模板具备概览的全部能力，同时支持虚拟事件与虚拟属性的呈现',
                  '支持通过模板配置概览后的二次编辑，可与概览双向转换，更为灵活易用'
                ],
                buttonList: [
                  <Button 
                  btnType="gradients" 
                    round
                    href="/doMatrixSa"
                  >体验全新 Demo<img className="icon-arrow" src="https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/icons/arrow-white.svg" alt="" style={{marginLeft: 10}}/></Button>,
                  <Button 
                  btnType="gradients" 
                    round
                    plain
                    href="https://manual.sensorsdata.cn/sa/latest/guide_scene-33915766.html"
                  >阅读产品文档<img className="icon-arrow" src="https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/icons/arrow-black.svg" alt="" style={{marginLeft: 10}}/></Button>
                ],
                img: Thumb02,
                imgAlt: 'thumb',
                imgWidth: 686
              },
              {
                className: 'np-03',
                title: '全新导航栏',
                desc: '跨产品线自由组合功能组件，更流畅的产品操作体验',
                list: [
                  '从用户习惯出发，在同一导航栏内实现不同产品线功能组件的融合，让涉及完整业务流程的所有组件调度更高效',
                  '您也可以保留产品线之间的切换功能，通过统一的产品线切换入口或浏览记录快速实现产品切换',
                  '导航栏变为双层设计，直观区分管理入口与功能入口，并支持菜单层级、图文描述等功能的自定义配置'
                ],
                buttonList: [
                  <Button 
                  btnType="gradients" 
                    round
                    href="/doMatrixSa"
                  >体验全新 Demo<img className="icon-arrow" src="https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/icons/arrow-white.svg" alt="" style={{marginLeft: 10}}/></Button>,
                  <Button 
                  btnType="gradients" 
                    round
                    plain
                    href="https://manual.sensorsdata.cn/sa/latest/page-33915981.html"
                  >阅读产品文档<img className="icon-arrow" src="https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/icons/arrow-black.svg" alt="" style={{marginLeft: 10}}/></Button>
                ],
                img: Thumb03,
                imgAlt: 'thumb',
                imgWidth: 671
              }
            ]} 
          />
        </SectionContent>
        <SectionContent className="new-old oval top" title="老客户免费升级 新客户全面开放">
          <div className="card-group">
            <CardSA2 
              className="card-item"
              icon={IconOld}
              title="面向老客户的可靠升级支持"
              desc={<>客户成功团队 7*24 小时保障服务质量<br />定制化升级方案适配您现有的产品组合<br />研发团队全程对接，专业技术升级支持<br />系统的新功能培训与操作详解</>}
            />
            <CardSA2 
              className="card-item"
              icon={IconNew}
              title="面向新客户的一站式价值交付"
              desc={<>快速形成数据驱动的业务基础<br />客户成功团队 1 对 1 陪伴式服务<br />体系化新用户引导与交付培训<br />强大的研发团队技术支持与部署保障</>}
            />
          </div>
        </SectionContent>
        <SectionContent className="bottom-wrap" style={{paddingTop: 0, paddingBottom: 40}}>
          <div className="divider"></div>
          <h1>立即体验，快人一步实现需求感知与业务决策的效率飞跃！</h1>
          <div className="sd-button-group">
            <Button 
              btnType="gradients" 
              round
              href="/doMatrixSa"
            >体验全新 Demo<img className="icon-arrow" src="https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/icons/arrow-white.svg" alt="" style={{marginLeft: 10}}/></Button>
          </div>
        </SectionContent>
      </main>
    </Layout>
  );
}

export default SA2_3;